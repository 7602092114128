import {BaseEnumDeserializer} from '../../../common';
import {fail, Result, some} from '../../../../utils';

export enum AirspaceDataSyncStatus {
  STARTING= 'STARTING',
  CHECKING_META = 'CHECKING_META',
  META_CHECKED = 'META_CHECKED',
  DOWNLOADING = 'DOWNLOADING',
  DOWNLOADED = 'DOWNLOADED',
  POPULATING_DB = 'POPULATING_DB',
  POPULATED_DB = 'POPULATED_DB',
  COMPLETED = 'COMPLETED',
}

export class AirspaceDataSyncStatusUtil implements BaseEnumDeserializer<AirspaceDataSyncStatus, AirspaceDataSyncStatus> {
  humanized: {[key in AirspaceDataSyncStatus]: string} = {
    [AirspaceDataSyncStatus.STARTING]: 'Starting',
    [AirspaceDataSyncStatus.CHECKING_META]: 'Checking meta',
    [AirspaceDataSyncStatus.META_CHECKED]: 'Meta checked',
    [AirspaceDataSyncStatus.DOWNLOADING]: 'Downloading',
    [AirspaceDataSyncStatus.DOWNLOADED]: 'Downloaded',
    [AirspaceDataSyncStatus.POPULATING_DB]: 'Populating database',
    [AirspaceDataSyncStatus.POPULATED_DB]: 'Populated database',
    [AirspaceDataSyncStatus.COMPLETED]: 'Completed',
  }

  deserialize(raw: unknown): Result<AirspaceDataSyncStatus> {
    if (typeof raw !== 'string') return fail('Invalid type for airspace data sync status');

    switch (raw.toUpperCase()) {
      case 'STARTING':
        return some(AirspaceDataSyncStatus.STARTING);
      case 'CHECKING_META':
        return some(AirspaceDataSyncStatus.CHECKING_META);
      case 'META_CHECKED':
        return some(AirspaceDataSyncStatus.META_CHECKED);
      case 'DOWNLOADING':
        return some(AirspaceDataSyncStatus.DOWNLOADING);
      case 'DOWNLOADED':
        return some(AirspaceDataSyncStatus.DOWNLOADED);
      case 'POPULATING_DB':
        return some(AirspaceDataSyncStatus.POPULATING_DB);
      case 'POPULATED_DB':
        return some(AirspaceDataSyncStatus.POPULATED_DB);
      case 'COMPLETED':
        return some(AirspaceDataSyncStatus.COMPLETED);
      default:
        return fail(`Invalid airspace data sync status: ${raw}`);
    }
  }
}

export const DEFAULT_AIRSPACE_DATA_SYNC_STATUS_UTIL = new AirspaceDataSyncStatusUtil();
