import {BaseEnumDeserializer} from '../../../common';
import {fail, Result, some} from '../../../../utils';

/**
 * Enum for supported Datatypes.
 */
export enum AirspaceDataType {
  /** UAS Facility Maps */
  UASFM = 'UASFM',
  /** Class airspace */
  CLASS_AIRSPACE = 'CLASS_AIRSPACE',
  /** Special Use Airspace */
  SPECIAL_USE_AIRSPACE = 'SPECIAL_USE_AIRSPACE',
  /** Full-time National Security Flight Restrictions */
  NSUFR_FT = 'NSUFR_FT',
  /** Part-time National Security Flight Restrictions */
  NSUFR_PT = 'NSUFR_PT',
  /** Stadiums */
  STADIUM = 'STADIUM',
  /** Airports */
  AIRPORT = 'AIRPORT',
  /** Airspace Schedules */
  AIRSPACE_SCHEDULE = 'AIRSPACE_SCHEDULE',
  /** Temporary flight restrictions */
  TFR = 'TFR',
  /** DC flight restriction zone */
  DC_FRZ = 'DC_FRZ',
}

export class AirspaceDataTypeUtil implements BaseEnumDeserializer<AirspaceDataType, AirspaceDataType> {
  humanized: {[key in AirspaceDataType]: string} = {
    UASFM: 'UAS Facility Map Data',
    CLASS_AIRSPACE: 'Class Airspace',
    SPECIAL_USE_AIRSPACE: 'Special Use Airspace',
    NSUFR_FT: 'Full-Time National Security UAS Flight Restrictions',
    NSUFR_PT: 'Part-Time National Security UAS Flight Restrictions',
    STADIUM: 'Stadiums',
    AIRPORT: 'Airports',
    AIRSPACE_SCHEDULE: 'Airspace Schedules',
    TFR: 'Temporary Flight Restrictions',
    DC_FRZ: 'DC Flight Restriction Zone',
  }

  deserialize(raw: unknown): Result<AirspaceDataType> {
    if (typeof raw !== 'string') return fail('Invalid type for airspace data type');

    switch (raw.toUpperCase()) {
      case 'UASFM':
        return some(AirspaceDataType.UASFM);
      case 'CLASS_AIRSPACE':
        return some(AirspaceDataType.CLASS_AIRSPACE);
      case 'SPECIAL_USE_AIRSPACE':
        return some(AirspaceDataType.SPECIAL_USE_AIRSPACE);
      case 'NSUFR_FT':
        return some(AirspaceDataType.NSUFR_FT);
      case 'NSUFR_PT':
        return some(AirspaceDataType.NSUFR_PT);
      case 'STADIUM':
        return some(AirspaceDataType.STADIUM);
      case 'AIRPORT':
        return some(AirspaceDataType.AIRPORT);
      case 'AIRSPACE_SCHEDULE':
        return some(AirspaceDataType.AIRSPACE_SCHEDULE);
      case 'TFR':
        return some(AirspaceDataType.TFR);
      case 'DC_FRZ':
        return some(AirspaceDataType.DC_FRZ);
      default:
        return fail(`Invalid airspace data type: ${raw}`);
    }
  }
}

export const DEFAULT_AIRSPACE_DATA_TYPE_UTIL = new AirspaceDataTypeUtil();
